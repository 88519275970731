import { IconButton, Slider, Tooltip, Typography } from '@mui/material'
import * as React from 'react'
import { Dataset } from '../../types/dataset.js'
import Box from '@mui/material/Box'
import { formatDate } from '../../utils.js'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { useMapContext } from '../../context/map/mapContext.js'

type DateSliderProps = {
    dataset: Dataset
}

function DateSlider({ dataset }: DateSliderProps) {
    const { dispatch } = useMapContext()
    const [value, setValue] = React.useState<number>(dataset.selectedDateIndex)

    // ensure local state is updated when the context state changes
    React.useEffect(() => {
        setValue(dataset.selectedDateIndex)
    }, [dataset.selectedDateIndex])

    function handleSliderOnChangeCommited(
        event: Event,
        newValue: number | number[]
    ) {
        dispatch({
            type: 'SET_SELECTED_DATE_INDEX',
            datasetVersionId: dataset.id,
            dateIndex: newValue as number,
        })
    }

    const date_labels = dataset.dates.map((d: Date, index: number) => {
        return {
            value: index,
            label: formatDate(d),
        }
    })

    return (
        <>
            {dataset.dates.length > 0 && (
                <Box
                    sx={{
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Typography variant="body2" width="100%">
                        {date_labels[dataset.selectedDateIndex].label}
                    </Typography>
                    <Tooltip title={'Previous timestep'}>
                        <span>
                            <IconButton
                                size="small"
                                sx={{
                                    marginRight: '10px',
                                }}
                                disabled={dataset.selectedDateIndex === 0}
                                onClick={() => {
                                    dispatch({
                                        type: 'SET_SELECTED_DATE_INDEX',
                                        datasetVersionId: dataset.id,
                                        dateIndex:
                                            dataset.selectedDateIndex - 1,
                                    })
                                }}
                            >
                                <KeyboardArrowLeft
                                    fontSize="inherit"
                                    color="primary"
                                />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Slider
                        size="small"
                        defaultValue={date_labels[0].value}
                        value={value}
                        step={1}
                        valueLabelDisplay="auto"
                        marks={false}
                        max={dataset.dates.length - 1}
                        onChange={(_event, newValue) => {
                            if (typeof newValue === 'number') {
                                setValue(newValue)
                            } else {
                                setValue(newValue[0])
                            }
                        }}
                        onChangeCommitted={handleSliderOnChangeCommited}
                        valueLabelFormat={(value, index) =>
                            date_labels[value].label
                        }
                    />
                    <Tooltip title={'Next timestep'}>
                        <span>
                            <IconButton
                                size="small"
                                sx={{
                                    marginLeft: '10px',
                                }}
                                disabled={
                                    dataset.selectedDateIndex ===
                                    dataset.dates.length - 1
                                }
                                onClick={() => {
                                    dispatch({
                                        type: 'SET_SELECTED_DATE_INDEX',
                                        datasetVersionId: dataset.id,
                                        dateIndex:
                                            dataset.selectedDateIndex + 1,
                                    })
                                }}
                            >
                                <KeyboardArrowRight
                                    fontSize="inherit"
                                    color="primary"
                                />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            )}
        </>
    )
}

export default DateSlider
