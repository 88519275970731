import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { StrictMode, useEffect } from 'react'
import 'swagger-ui-react/swagger-ui.css'
import { createTheme, ThemeProvider } from '@mui/material'
import 'highlight.js/styles/atom-one-dark.css'

import { themeOptions } from './theme'

import Workspace from './pages/Workspace/Workspace'
import SupabaseProvider from './context/supabase/SupabaseProvider'
import { GlobalCSS } from '../GlobalCSS'
import * as Sentry from '@sentry/react'
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
    useParams,
} from 'react-router-dom'
import MapProvider from './context/map/MapProvider'
import { useSupabaseContext } from './context/supabase/supabaseContext'
import CacheManager from './context/cache'
import { ErrorEvent } from 'react-map-gl/maplibre'
import { EventHint } from '@sentry/react'
import ChangePassword from './pages/ChangePassword/ChangePassword'
import MagicSearch from './components/MagicSearch/MagicSearch'
import CommentProvider from './context/comment/CommentProvider'
import { supabaseIntegration } from '@supabase/sentry-js-integration'
import { SupabaseClient } from '@supabase/supabase-js'

CacheManager.init(process.env.VERCEL_GIT_COMMIT_SHA)

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: import.meta.env.EARTHSCALE_ENVIRONMENT,
    release: process.env.VERCEL_GIT_COMMIT_SHA,
    integrations: [
        Sentry.httpClientIntegration({
            failedRequestStatusCodes: [400, 599],
        }),
        supabaseIntegration(SupabaseClient, Sentry, {
            tracing: true,
            breadcrumbs: true,
            errors: true,
        }),
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
        Sentry.replayCanvasIntegration(),
    ],
    beforeSend: (event: ErrorEvent, eventHint: EventHint) => {
        // log exception if dev
        if (import.meta.env.DEV) {
            if (eventHint.originalException) {
                console.error(eventHint.originalException)
            }
            return null
        }
        return event
    },
    tracesSampleRate: 1.0, // This captures 100% of transactions
    tracePropagationTargets: [/^https:\/\/backend\.earthscale\.ai/],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This captures 100% of sessions
    replaysOnErrorSampleRate: 1.0, // This captures 100% of sessions with errors (if not already sampling 100% of sessions)
})

const theme = createTheme(themeOptions)

function MapRoute() {
    const { id } = useParams()
    const { user } = useSupabaseContext()
    const fallbackMapId = id || user?.default_map_id || undefined

    return (
        <MapProvider fallbackMapId={fallbackMapId}>
            <CommentProvider>
                <Workspace />
            </CommentProvider>
        </MapProvider>
    )
}

function ExploreRoute() {
    const { id } = useParams()
    const { user } = useSupabaseContext()
    const fallbackMapId = id || user?.default_map_id || undefined
    return (
        <MapProvider fallbackMapId={fallbackMapId}>
            <MagicSearch />
        </MapProvider>
    )
}

export default function App() {
    const { user } = useSupabaseContext()
    const isPersonalOrg = user?.org_name === 'personal'

    return (
        // The supabase provider makes sure the user is logged in
        <BrowserRouter>
            <Routes>
                <Route path="/explore" element={<ExploreRoute />} />
                <Route
                    path="/map/:id"
                    element={
                        isPersonalOrg ? (
                            <Navigate to="/explore" replace />
                        ) : (
                            <MapRoute />
                        )
                    }
                />
                <Route
                    path="/"
                    element={
                        isPersonalOrg ? (
                            <Navigate to="/explore" replace />
                        ) : (
                            <MapRoute />
                        )
                    }
                />
                <Route
                    path="/user/change-password"
                    element={<ChangePassword />}
                />
            </Routes>
        </BrowserRouter>
    )
}

export function renderToDom(container) {
    createRoot(container).render(
        <StrictMode>
            <ThemeProvider theme={theme}>
                <GlobalCSS />
                <SupabaseProvider>
                    <App />
                </SupabaseProvider>
            </ThemeProvider>
        </StrictMode>
    )
}
