import {
    Button,
    Checkbox,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    Tooltip,
} from '@mui/material'
import DatasetCard from '../DatasetCard/DatasetCard.tsx'
import * as React from 'react'
import { Dataset } from '../../types/dataset.ts'
import { useMapContext } from '../../context/map/mapContext.ts'
import { Add, Close, Remove } from '@mui/icons-material'
import { useCallback, useEffect } from 'react'
import { debounce } from 'lodash'
import Box from '@mui/material/Box'

const AddToMapButton = ({ dataset }: { dataset: Dataset }) => {
    const { state, addDatasetToMap, removeDatasetFromMap } = useMapContext()
    const datasetOnMap = state.current.datasets
        .map((dataset) => dataset.id)
        .includes(dataset.id)
    const tooltipText = datasetOnMap
        ? 'Remove dataset from map'
        : 'Add dataset to map'

    const handleButtonClick = () => {
        if (datasetOnMap) {
            removeDatasetFromMap(dataset.id)
        } else {
            addDatasetToMap(dataset)
        }
    }

    return (
        <Tooltip title={tooltipText}>
            <IconButton
                onClick={(e) => {
                    handleButtonClick()
                    e.stopPropagation()
                }}
            >
                {datasetOnMap ? <Remove /> : <Add />}
            </IconButton>
        </Tooltip>
    )
}

type DatasetListProps = {
    datasets: Dataset[]
    flyToDatasetBounds: (dataset: Dataset) => void
    showVizSettings: boolean
    showSelector: boolean
    showRemoveFromMap: boolean
    showVisibility: boolean
    disableNonActive: boolean
}

function DatasetList({
    datasets,
    flyToDatasetBounds,
    showVizSettings,
    showSelector,
    showRemoveFromMap,
    showVisibility,
    disableNonActive,
}: DatasetListProps) {
    const { dispatch, state } = useMapContext()

    const debouncedMouseEnter = useCallback(
        debounce((datasetVersionId) => {
            dispatch({
                type: 'HOVER_CARD',
                datasetVersionId: datasetVersionId,
            })
        }, 100),
        []
    )

    const debouncedMouseLeave = useCallback(
        debounce(() => {
            dispatch({
                type: 'HOVER_CARD',
                datasetVersionId: null,
            })
        }, 100),
        []
    )

    // check if there exist two datasets with the same id
    useEffect(() => {
        const datasetIds = new Set()
        datasets.forEach((dataset) => {
            if (datasetIds.has(dataset.id)) {
                console.error(
                    `There are two datasets with the same id: ${dataset.id}`
                )
            } else {
                datasetIds.add(dataset.id)
            }
        })
    }, [datasets])

    return (
        <List disablePadding>
            {datasets.map((dataset) => (
                <ListItem
                    key={dataset.id}
                    // This is important as it will make the hover work correctly
                    disablePadding
                    secondaryAction={
                        showSelector &&
                        dataset.status === 'ready' && (
                            <AddToMapButton dataset={dataset} />
                        )
                    }
                    // Tried moving this to the `ListItemButton`, but that didn't
                    // work as events weren't fired then. Tried moving it as this is
                    // deprecated.
                    disabled={disableNonActive && !dataset.isVisible}
                    sx={{
                        '& .MuiListItemSecondaryAction-root': {
                            top: 0,
                            transform: 'translateY(0%)',
                        },
                    }}
                >
                    <ListItemButton
                        sx={{
                            borderRadius: '8px',
                            paddingLeft: '8px',
                            paddingRight: '8px',
                            paddingTop: 0,
                            paddingBottom: 0,
                        }}
                        onClick={() => {
                            if (
                                dataset.status === 'ready' ||
                                dataset.status === 'processing_failed'
                            ) {
                                dispatch({
                                    type: 'SELECT_DATASET',
                                    datasetVersionId: dataset.id,
                                })
                            }
                        }}
                        onMouseEnter={() => debouncedMouseEnter(dataset.id)}
                        onMouseLeave={() => debouncedMouseLeave(dataset.id)}
                    >
                        <DatasetCard
                            dataset={dataset}
                            flyToDatasetBounds={flyToDatasetBounds}
                            showVizSettings={showVizSettings}
                            showRemoveFromMap={showRemoveFromMap}
                            showVisibility={showVisibility}
                        />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    )
}

export default DatasetList
