import {
    convertDBDatasetToDataset,
    DBDataset,
    DBVizParams,
    insertVizParams,
} from './dataset.ts'
import { Map, MapMetadata } from '../types/map.ts'
import { SupabaseClient } from '@supabase/supabase-js'
import { Viz, VizType } from '../types/viz.ts'
import { getLatestVizParamsForDatasetVersion } from './viz.ts'
import { getOrgId } from './user.ts'
import { v4 as uuid } from 'uuid'

export async function createMap(
    supabaseClient: SupabaseClient
): Promise<string> {
    const orgId = await getOrgId(supabaseClient)
    const mapId = uuid()
    const { data, error } = await supabaseClient.from('maps').insert({
        id: mapId,
        name: `New map (${new Date().toLocaleString('en-us', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        })})`,
        description: 'Click the map title or this description to edit it',
        org_id: orgId,
    })

    if (error) {
        throw error
    }
    return mapId
}

export async function fetchMapsMetadata(
    supabaseClient: SupabaseClient
): Promise<MapMetadata[]> {
    const { data, error } = await supabaseClient.from('maps').select('*')

    if (error) {
        throw error
    }

    return data
}

export async function fetchMapById(
    supabaseClient: any,
    id: string
): Promise<Map> {
    const { data, error } = await supabaseClient
        .from('maps')
        .select('*, map_entries(*, dataset_versions(*), viz_params(*))')
        .eq('id', id)
        .single()

    if (error) {
        throw error
    }

    const datasets = await Promise.all(
        data.map_entries.map(async (entry) => {
            // There's a 1:1 relationship between viz_params and datasets, thus the type
            // casting
            const dbVizParams = entry.viz_params as DBVizParams
            let viz: Viz | null = null
            if (dbVizParams) {
                viz = {
                    id: dbVizParams.id,
                    vizType: dbVizParams.type,
                    vizParams: dbVizParams.params,
                }
            }
            return await convertDBDatasetToDataset(
                entry.dataset_versions,
                viz,
                supabaseClient
            )
        })
    )

    return {
        metadata: {
            id: data.id,
            name: data.name,
            description: data.description,
        },
        datasets: datasets,
    }
}

type _MapEntry = {
    id: string
    datasets: DBDataset
}

export async function addDatasetToMap(
    supabaseClient: any,
    mapId: string,
    datasetVersionId: string
) {
    const latestViz = await getLatestVizParamsForDatasetVersion(
        supabaseClient,
        datasetVersionId
    )
    const vizId = latestViz ? latestViz.id : null

    const { _, dataset_error } = await supabaseClient
        .from('map_entries')
        .insert({
            map_id: mapId,
            dataset_version_id: datasetVersionId,
            viz_params_id: vizId,
        })

    if (dataset_error) {
        throw dataset_error
    }
}

export async function removeDatasetFromMap(
    supabaseClient: any,
    mapId: string,
    datasetVersionId: string
) {
    const { _, error } = await supabaseClient
        .from('map_entries')
        .delete()
        .eq('map_id', mapId)
        .eq('dataset_version_id', datasetVersionId)

    if (error) {
        throw error
    }
}

export async function updateVizParamsForMap(
    supabaseClient: any,
    mapId: string,
    datasetVersionId: string,
    datasetName: string,
    vizId: string,
    vizParams: any,
    vizType: VizType
) {
    await insertVizParams(
        supabaseClient,
        datasetVersionId,
        datasetName,
        vizId,
        vizType,
        vizParams
    )
    // Update the viz params in the map_entries table
    const { _, error } = await supabaseClient
        .from('map_entries')
        .update({
            viz_params_id: vizId,
        })
        .eq('map_id', mapId)
        .eq('dataset_version_id', datasetVersionId)

    if (error) {
        throw error
    }
}
