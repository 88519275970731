import React from 'react'
import { createClient, Session } from '@supabase/supabase-js'
import {
    EarthscaleUser,
    fetchEarthscaleUser,
    fetchUserProfiles,
} from '../../api/user.ts'
import Login from '../../pages/Login/Login.tsx'
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen.tsx'
import { SupabaseContext } from './supabaseContext.ts'
import * as Sentry from '@sentry/browser'
import { UserProfile } from '../../types/user'

const supabaseClient = createClient(
    import.meta.env.EARTHSCALE_SUPABASE_URL,
    import.meta.env.EARTHSCALE_SUPABASE_ANON_KEY
)

interface CurrentUserState {
    user: EarthscaleUser
    userProfile: UserProfile
}

const SupabaseProvider = ({ children }) => {
    const loginMapRef = React.useRef(null)

    // Handles auth and makes sure the user is logged in
    const [supabaseSession, setSupabaseSession] =
        React.useState<Session | null>(null)

    const [currentUser, setCurrentUser] =
        React.useState<CurrentUserState | null>(null)

    const signOut = () => {
        supabaseClient.auth.signOut()
        setSupabaseSession(null)
    }

    React.useEffect(() => {
        const {
            data: { subscription },
        } = supabaseClient.auth.onAuthStateChange((_event, session) => {
            setSupabaseSession(session)
            Sentry.setUser({
                id: session?.user?.id,
                email: session?.user?.email,
            })
        })

        return () => {
            subscription?.unsubscribe()
        }
    }, [])

    React.useEffect(() => {
        // We need to call getSession() every once in a while as it will automatically
        // refresh the JWT token avoiding the user to see strange errors.
        // Currently refreshing every minute here
        const refreshSession = async () => {
            const { data, error } = await supabaseClient.auth.getSession()
            if (error) {
                console.error('Error refreshing session:', error)
                signOut()
            } else {
                // We've seen issues where an old JWT token is used after the database
                // is reset. We won't be able to fetch the user in this case, so we'll
                // just log out the user.
                await supabaseClient.auth.getUser().then((userResponse) => {
                    if (userResponse.error) {
                        signOut()
                    }
                    if (!userResponse) {
                        signOut()
                    } else {
                        setSupabaseSession(data.session)
                    }
                })
            }
        }

        refreshSession()

        const interval = setInterval(() => {
            refreshSession()
        }, 1000 * 60)

        return () => clearInterval(interval)
    }, [])

    // Fetch current user using the supabaseSession
    React.useEffect(() => {
        if (!supabaseSession) {
            setCurrentUser(null)
            return
        }
        const fetchUserData = async (): CurrentUserState | null => {
            const user = await fetchEarthscaleUser(supabaseClient)
            if (!user) {
                Sentry.captureEvent({
                    level: Sentry.Severity.Error,
                    message: 'User not found',
                    extra: {
                        user_id: supabaseSession.user.id,
                    },
                })
                return null
            }
            const userProfiles = await fetchUserProfiles(supabaseClient, [
                user.id,
            ])
            if (!userProfiles || userProfiles.length === 0) {
                Sentry.captureEvent({
                    level: Sentry.Severity.Error,
                    message: 'User profile not found',
                    extra: {
                        user_id: user.id,
                    },
                })
                return null
            }
            return { user, userProfile: userProfiles[0] }
        }
        fetchUserData().then((userData: CurrentUserState | null) => {
            if (userData === null) {
                setSupabaseSession(null)
            } else {
                setCurrentUser(userData)
            }
        })
    }, [supabaseSession])

    const logout = async () => {
        await supabaseClient.auth.signOut()
        setSupabaseSession(null)
    }

    if (!supabaseSession) {
        return <Login supabaseClient={supabaseClient} mapRef={loginMapRef} />
    } else if (!currentUser) {
        return <LoadingScreen />
    } else {
        return (
            <SupabaseContext.Provider
                value={{
                    client: supabaseClient,
                    session: supabaseSession,
                    user: currentUser.user,
                    userProfile: currentUser.userProfile,
                    logout: logout,
                    loginMapRef: loginMapRef,
                }}
            >
                {children}
            </SupabaseContext.Provider>
        )
    }
}

export default SupabaseProvider
