import { LocalDataset } from '../types/localDataset.ts'
import { LOCAL_DATASETS_LOCAL_STORAGE_KEY } from '../constants.ts'
import CacheManager from '../context/cache'

function loadLocalDatasets(): LocalDataset[] {
    const localDatasets = CacheManager.getItem(LOCAL_DATASETS_LOCAL_STORAGE_KEY)
    if (localDatasets) {
        return JSON.parse(localDatasets)
    }
    return []
}

function loadLocalDatasetsForMap(mapId: string): LocalDataset[] {
    return loadLocalDatasets().filter((d) => d.mapId === mapId)
}

function addLocalDataset(dataset: LocalDataset) {
    const localDatasets = loadLocalDatasets()
    // Check whether a dataset with the same ID and filename already exists, if so
    // don't add it
    const exists = localDatasets.some(
        (d) => d.mapId === dataset.mapId && d.filename === dataset.filename
    )
    if (!exists) {
        localDatasets.push(dataset)
    }
    CacheManager.setItem(
        LOCAL_DATASETS_LOCAL_STORAGE_KEY,
        JSON.stringify(localDatasets)
    )
}

function computeBounds(
    geometry: GeoJSON.Geometry
): [number, number, number, number] {
    if (geometry.type === 'Point') {
        return [
            geometry.coordinates[0],
            geometry.coordinates[1],
            geometry.coordinates[0],
            geometry.coordinates[1],
        ]
    } else if (geometry.type === 'LineString') {
        let minX = Infinity
        let minY = Infinity
        let maxX = -Infinity
        let maxY = -Infinity
        geometry.coordinates.forEach((coord) => {
            minX = Math.min(minX, coord[0])
            minY = Math.min(minY, coord[1])
            maxX = Math.max(maxX, coord[0])
            maxY = Math.max(maxY, coord[1])
        })
        return [minX, minY, maxX, maxY]
    } else if (geometry.type === 'Polygon') {
        let minX = Infinity
        let minY = Infinity
        let maxX = -Infinity
        let maxY = -Infinity
        geometry.coordinates[0].forEach((coord) => {
            minX = Math.min(minX, coord[0])
            minY = Math.min(minY, coord[1])
            maxX = Math.max(maxX, coord[0])
            maxY = Math.max(maxY, coord[1])
        })
        return [minX, minY, maxX, maxY]
    } else if (geometry.type === 'MultiPoint') {
        let minX = Infinity
        let minY = Infinity
        let maxX = -Infinity
        let maxY = -Infinity
        geometry.coordinates.forEach((coord) => {
            minX = Math.min(minX, coord[0])
            minY = Math.min(minY, coord[1])
            maxX = Math.max(maxX, coord[0])
            maxY = Math.max(maxY, coord[1])
        })
        return [minX, minY, maxX, maxY]
    } else if (geometry.type === 'MultiLineString') {
        let minX = Infinity
        let minY = Infinity
        let maxX = -Infinity
        let maxY = -Infinity
        geometry.coordinates.forEach((lineString) => {
            lineString.forEach((coord) => {
                minX = Math.min(minX, coord[0])
                minY = Math.min(minY, coord[1])
                maxX = Math.max(maxX, coord[0])
                maxY = Math.max(maxY, coord[1])
            })
        })
        return [minX, minY, maxX, maxY]
    } else if (geometry.type === 'MultiPolygon') {
        let minX = Infinity
        let minY = Infinity
        let maxX = -Infinity
        let maxY = -Infinity
        geometry.coordinates.forEach((polygon) => {
            polygon[0].forEach((coord) => {
                minX = Math.min(minX, coord[0])
                minY = Math.min(minY, coord[1])
                maxX = Math.max(maxX, coord[0])
                maxY = Math.max(maxY, coord[1])
            })
        })
        return [minX, minY, maxX, maxY]
    } else if (geometry.type === 'GeometryCollection') {
        let minX = Infinity
        let minY = Infinity
        let maxX = -Infinity
        let maxY = -Infinity
        geometry.geometries.forEach((g) => {
            const bounds = computeBounds(g)
            minX = Math.min(minX, bounds[0])
            minY = Math.min(minY, bounds[1])
            maxX = Math.max(maxX, bounds[2])
            maxY = Math.max(maxY, bounds[3])
        })
        return [minX, minY, maxX, maxY]
    } else if (geometry.type === 'Feature') {
        return computeBounds(geometry.geometry)
    } else if (geometry.type === 'FeatureCollection') {
        return computeBoundsForFeatureCollection(geometry)
    }
    throw new Error(`Unsupported geometry type: ${geometry.type}`)
}

function computeBoundsForFeatureCollection(
    featureCollection: GeoJSON.FeatureCollection
): [number, number, number, number] {
    let minX = Infinity
    let minY = Infinity
    let maxX = -Infinity
    let maxY = -Infinity
    featureCollection.features.forEach((feature) => {
        if (feature.geometry) {
            const bounds = computeBounds(feature.geometry)
            minX = Math.min(minX, bounds[0])
            minY = Math.min(minY, bounds[1])
            maxX = Math.max(maxX, bounds[2])
            maxY = Math.max(maxY, bounds[3])
        }
    })
    return [minX, minY, maxX, maxY]
}

function convertLocalDatasetToDataset(localDataset: LocalDataset): Dataset {
    // Extent is a postgres BOX string
    const bounds = computeBoundsForFeatureCollection(localDataset.geometry)
    const extent = `BOX(${bounds[0]} ${bounds[1]},${bounds[2]} ${bounds[3]})`

    const defaultVectorVizParams = {
        mode: 'fill',
        color: '#000000',
        width: 2,
    }

    return {
        id: localDataset.mapId + localDataset.filename,
        name: localDataset.filename,
        createdAt: localDataset.createdAt,
        updatedAt: localDataset.createdAt,
        dates: [],
        extent: extent,
        isDeleted: false,
        domain: 'WORKSPACE',
        type: 'vector',
        status: 'ready',
        className: 'local-dataset',
        source: 'local',
        minZoom: 0,
        vizParams: defaultVectorVizParams,
        vizType: 'vector',
        isVisible: true,
        selectedDateIndex: 0,
        isBeingEdited: false,
        cardIsHovered: false,
    }
}

function removeLocalDataset(mapId: string, filename: string) {
    const localDatasets = loadLocalDatasets()
    const newLocalDatasets = localDatasets.filter(
        (d) => d.mapId !== mapId || d.filename !== filename
    )
    CacheManager.setItem(
        LOCAL_DATASETS_LOCAL_STORAGE_KEY,
        JSON.stringify(newLocalDatasets)
    )
}

export {
    loadLocalDatasets,
    loadLocalDatasetsForMap,
    convertLocalDatasetToDataset,
    addLocalDataset,
    removeLocalDataset,
}
