import React, { useState } from 'react'
import { Menu, MenuItem, Button, Typography } from '@mui/material'
import { MapMetadata } from '../../types/map.ts'
import { useMapContext } from '../../context/map/mapContext.ts'
import { fetchMapsMetadata } from '../../api/map.ts'
import { useSupabaseContext } from '../../context/supabase/supabaseContext.ts'
import Box from '@mui/material/Box'
import Lottie from 'react-lottie-player'
import * as loadingSpinner from '../../assets/loadingSpinnner/white-on-transparent.json'
import { Add } from '@mui/icons-material'
import * as Sentry from '@sentry/react'

interface MapSelectorProps {
    anchorEl: HTMLElement | null
    setAnchorEl: (anchorEl: HTMLElement | null) => void
}

const MapSelector: React.FC<MapSelectorProps> = ({ anchorEl, setAnchorEl }) => {
    const supabase = useSupabaseContext()
    const [allMaps, setAllMaps] = useState<MapMetadata[]>([])
    const { createNewMap, changeMap } = useMapContext()

    // Fetch maps
    React.useEffect(() => {
        if (allMaps.length == 0) {
            fetchMapsMetadata(supabase.client)
                .then((data) => {
                    setAllMaps(data)
                })
                .catch((e) => {
                    Sentry.captureException(e)
                    alert(
                        'Sorry, we are having trouble loading your maps. Please log in and try again.'
                    )
                    supabase.logout()
                })
        }
    })

    const handleClose = (map?: MapMetadata) => {
        setAnchorEl(null)
        if (map) {
            changeMap(map.id)
        }
    }

    return (
        <Menu
            id="map-selector-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
                handleClose()
                setAllMaps([])
            }}
            slotProps={{
                paper: {
                    style: {
                        maxHeight: '500px', // Set the maximum height for the menu
                        overflowY: 'auto', // Enable vertical scrolling
                        width: '300px',
                        padding: '8px',
                    },
                },
            }}
        >
            {/*button should be horizontally cenerted*/}
            <Box
                display="flex"
                justifyContent="right"
                marginBottom="20px"
                width={'100%'}
            >
                <Button
                    variant="text"
                    onClick={() => {
                        createNewMap()
                        setAnchorEl(null)
                    }}
                    endIcon={<Add fontSize="small" />}
                >
                    Create new map
                </Button>
            </Box>
            {allMaps.length === 0 && (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Lottie
                        loop
                        animationData={loadingSpinner}
                        play
                        style={{
                            width: 70,
                            height: 70,
                        }}
                    />
                </Box>
            )}
            {allMaps.map((map) => (
                <MenuItem
                    key={map.id}
                    onClick={() => handleClose(map)}
                    divider={true}
                    sx={{ borderRadius: '8px' }}
                >
                    <div style={{ maxWidth: '300px' }}>
                        <Typography variant="body1">{map.name}</Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                            }}
                        >
                            {map.description}
                        </Typography>
                    </div>
                </MenuItem>
            ))}
        </Menu>
    )
}

export default MapSelector
