import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { Dataset } from '../../types/dataset.ts'
import Box from '@mui/material/Box'
import { useMapContext } from '../../context/map/mapContext.ts'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import eyeOpen from '../../assets/icons/eye-open.svg'
import mapPin from '../../assets/icons/map-pin.svg'
import DatasetCardStyle from './DatasetCard.module.css'

type VisibilityButtonProps = {
    dataset: Dataset
}

function VisibilityButton({ dataset }: VisibilityButtonProps) {
    const { dispatch } = useMapContext()

    const toggleVisibility = (e: React.MouseEvent) => {
        e.stopPropagation()
        dispatch({
            type: 'TOGGLE_DATASET_VISIBILITY',
            datasetVersionId: dataset.id,
        })
    }

    return (
        <Box>
            <Tooltip title={dataset.isVisible ? 'Hide' : 'Show'} arrow>
                {dataset.isVisible ? (
                    <IconButton onClick={toggleVisibility}>
                        <img
                            src={eyeOpen as string}
                            alt="Hide"
                            className={DatasetCardStyle.icon}
                            style={{ width: '18px', height: '18px' }}
                        />
                    </IconButton>
                ) : (
                    <IconButton onClick={toggleVisibility}>
                        <VisibilityOffIcon
                            className={DatasetCardStyle.icon}
                            style={{ width: '18px', height: '18px' }}
                        />
                    </IconButton>
                )}
            </Tooltip>
        </Box>
    )
}

export default VisibilityButton
