import ReactDOM from 'react-dom'
import {
    Box,
    ClickAwayListener,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import { v4 as uuid } from 'uuid'
import React from 'react'
import { Dataset } from '../../types/dataset.ts'
import { SketchPicker } from 'react-color'
import { ArrowBack } from '@mui/icons-material'
import {
    VizType,
    VectorVizParams,
    PseudocolorRasterVizParams,
    MultibandRasterVizParams,
    CategoricalRasterVizParams,
    VizParams,
    getDefaultRasterVizParams,
} from '../../types/viz.ts'
import VisibilityButton from '../DatasetCard/VisibilityButton'
import ZoomButton from '../DatasetCard/ZoomButton'
import VizParamsEditorStyle from './VizParamsEditor.module.css'
import CacheManager from '../../context/cache'

const COLOR_RAMPS = ['viridis', 'plasma', 'inferno', 'magma', 'cividis']

const CATEGORICAL_COLOR_MAPS = [
    'paired',
    'accent',
    'viridis',
    'hsv',
    'tab10',
    'tab20',
    'tab20b',
    'tab20c',
]

const FloatingBox = ({ children, top, left }) => {
    return ReactDOM.createPortal(
        <Box
            sx={{
                position: 'absolute',
                top: top,
                left: left,
                zIndex: 2000,
            }}
        >
            {children}
        </Box>,
        document.body
    )
}

function VectorVizParamsEditor({ dataset }: { dataset: Dataset }) {
    const { updateVizParams } = useMapContext()
    const vizParams: VectorVizParams = dataset.vizParams as VectorVizParams
    const handleChange = (field: string, value: string | number) => {
        const newVizParams = {
            ...dataset.vizParams,
            [field]: value,
        } as VizParams
        updateVizParams(dataset, newVizParams, dataset.vizType)
    }
    const [colorPickerVisible, setColorPickerVisible] = React.useState(false)
    const [colorPickerPosition, setColorPickerPosition] = React.useState({
        top: 0,
        left: 0,
    })

    const handleColorPickerClick = (event: React.MouseEvent) => {
        setColorPickerPosition({ top: event.clientY, left: event.clientX })
        setColorPickerVisible(!colorPickerVisible)
    }

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                <InputLabel id="vector-type-label">
                    Visualization Type
                </InputLabel>
                <Select
                    labelId="vector-type-label"
                    value={vizParams.mode}
                    label="Visualization Type"
                    sx={{
                        '& fieldset': {
                            borderColor: 'primary.main',
                        },
                    }}
                    onChange={(e) => handleChange('mode', e.target.value)}
                >
                    <MenuItem value="fill">Fill</MenuItem>
                    <MenuItem value="outline">Outline</MenuItem>
                </Select>
            </FormControl>

            <Box sx={{ mb: 2 }}>
                <Typography gutterBottom>Color</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={handleColorPickerClick}
                >
                    <Box
                        sx={{
                            width: 36,
                            height: 14,
                            borderRadius: 1,
                            backgroundColor: vizParams.color,
                            marginRight: 1,
                        }}
                    />
                    <Typography>{vizParams.color}</Typography>
                </Box>
                {colorPickerVisible && (
                    <FloatingBox
                        top={colorPickerPosition.top}
                        left={colorPickerPosition.left}
                    >
                        <ClickAwayListener
                            onClickAway={() => setColorPickerVisible(false)}
                        >
                            <Box>
                                <SketchPicker
                                    color={vizParams.color}
                                    onChangeComplete={(color) => {
                                        handleChange('color', color.hex)
                                        setColorPickerVisible(false)
                                    }}
                                />
                            </Box>
                        </ClickAwayListener>
                    </FloatingBox>
                )}
            </Box>

            {vizParams.mode === 'outline' && (
                <Box sx={{ width: '100%', mb: 2 }}>
                    <Typography id="width-slider" gutterBottom>
                        Width (px)
                    </Typography>
                    <Slider
                        aria-labelledby="width-slider"
                        value={vizParams.width || 1}
                        onChange={(_, newValue: number) => {
                            return handleChange('width', newValue)
                        }}
                        valueLabelDisplay="auto"
                        step={0.5}
                        marks
                        min={0.5}
                        max={5}
                    />
                </Box>
            )}
        </Box>
    )
}

function CategoricalVizParamsEditor({ dataset }: { dataset: Dataset }) {
    const { updateVizParams } = useMapContext()
    const vizParams: CategoricalRasterVizParams =
        dataset.vizParams as CategoricalRasterVizParams

    const updateParam = (
        param: keyof CategoricalRasterVizParams,
        value: any
    ) => {
        updateVizParams(
            dataset,
            {
                ...dataset.vizParams,
                [param]: value,
            } as VizParams,
            dataset.vizType
        )
    }

    return (
        <Box>
            <FormControl fullWidth sx={{ mb: 2, mt: '4vh' }}>
                <InputLabel id="color-map-label ">Color Map</InputLabel>
                <Select
                    labelId="color-ramp-label"
                    id="color-ramp"
                    value={vizParams.colorMap}
                    label="Color Ramp"
                    onChange={(event) => {
                        updateParam('colorMap', event.target.value)
                    }}
                    sx={{
                        '& fieldset': {
                            borderColor: 'primary.main',
                        },
                    }}
                >
                    {CATEGORICAL_COLOR_MAPS.map((colorMap) => (
                        <MenuItem key={colorMap} value={colorMap}>
                            {colorMap}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )
}

import React, { useState, useEffect } from 'react'
import { useMapContext } from '../../context/map/mapContext.ts'
import { produce } from 'immer'

function SingleBandVizParamsEditor({
    dataset,
    localVizParams,
    setLocalVizParams,
}: {
    dataset: Dataset
    localVizParams: PseudocolorRasterVizParams
    setLocalVizParams: (vizParams: PseudocolorRasterVizParams) => void
}) {
    const updateParam = (
        param: keyof PseudocolorRasterVizParams,
        value: any
    ) => {
        setLocalVizParams((prev) => ({
            ...prev,
            [param]: value,
        }))
    }

    const updateMinOrMax = (
        band: string,
        minOrMax: 'min' | 'max',
        value: number | null
    ) => {
        setLocalVizParams((prev) => {
            const currentMinMax = prev.minMaxesPerBand[band]
            const newMin = minOrMax === 'min' ? value : currentMinMax[0]
            const newMax = minOrMax === 'max' ? value : currentMinMax[1]
            return {
                ...prev,
                minMaxesPerBand: {
                    ...prev.minMaxesPerBand,
                    [band]: [newMin, newMax] as [number, number],
                },
            }
        })
    }

    const currentBand = localVizParams.band
    const currentMin = localVizParams.minMaxesPerBand[currentBand][0]
    const currentMax = localVizParams.minMaxesPerBand[currentBand][1]

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="band-selector-label">Band</InputLabel>
                <Select
                    required
                    labelId="band-selector-label"
                    id="band-selector"
                    value={localVizParams.band ?? ''}
                    label="Band"
                    onChange={(event) => {
                        updateParam('band', event.target.value)
                    }}
                    sx={{
                        '& fieldset': {
                            borderColor: 'primary.main',
                        },
                    }}
                    error={localVizParams.band === undefined}
                >
                    {dataset.bands.map((band) => (
                        <MenuItem key={band} value={band}>
                            {band}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="color-ramp-label ">Color Ramp</InputLabel>
                <Select
                    labelId="color-ramp-label"
                    id="color-ramp"
                    value={localVizParams.colorRamp}
                    label="Color Ramp"
                    onChange={(event) => {
                        updateParam('colorRamp', event.target.value)
                    }}
                    sx={{
                        '& fieldset': {
                            borderColor: 'primary.main',
                        },
                    }}
                >
                    {COLOR_RAMPS.map((colorRamp) => (
                        <MenuItem key={colorRamp} value={colorRamp}>
                            {colorRamp}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Box
                sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}
            >
                <FormControl sx={{ width: '48%' }}>
                    <TextField
                        required
                        id="min-value"
                        label="Min"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{
                            '& fieldset': {
                                borderColor: 'primary.main',
                            },
                        }}
                        value={currentMin ?? ''}
                        onChange={(event) => {
                            const value = event.target.value
                            if (value === '' || !isNaN(parseFloat(value))) {
                                updateMinOrMax(
                                    currentBand,
                                    'min',
                                    value === '' ? null : parseFloat(value)
                                )
                            }
                        }}
                        error={currentMin === null}
                        helperText={currentMin === null ? 'Required' : ''}
                    />
                </FormControl>
                <FormControl sx={{ width: '48%' }}>
                    <TextField
                        required
                        id="max-value"
                        label="Max"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{
                            '& fieldset': {
                                borderColor: 'primary.main',
                            },
                        }}
                        value={currentMax ?? ''}
                        onChange={(event) => {
                            const value = event.target.value
                            if (value === '' || !isNaN(parseFloat(value))) {
                                updateMinOrMax(
                                    currentBand,
                                    'max',
                                    value === '' ? null : parseFloat(value)
                                )
                            }
                        }}
                        error={currentMax === null}
                        helperText={currentMax === null ? 'Required' : ''}
                    />
                </FormControl>
            </Box>
        </Box>
    )
}

function MultibandVizParamsEditor({
    dataset,
    localVizParams,
    setLocalVizParams,
}: {
    dataset: Dataset
    localVizParams: MultibandRasterVizParams
    setLocalVizParams: (vizParams: MultibandRasterVizParams) => void
}) {
    const updateParam = (param: keyof MultibandRasterVizParams, value: any) => {
        setLocalVizParams((prev) => ({
            ...prev,
            [param]: value,
        }))
    }

    const updateMinOrMax = (
        band: string,
        minOrMax: 'min' | 'max',
        value: number | null
    ) => {
        setLocalVizParams(
            produce((draft: MultibandRasterVizParams) => {
                draft.minMaxesPerBand[band] = [
                    minOrMax === 'min' ? value : draft.minMaxesPerBand[band][0],
                    minOrMax === 'max' ? value : draft.minMaxesPerBand[band][1],
                ]
            })
        )
    }

    return (
        <Box sx={{ minWidth: 120 }}>
            {['red', 'green', 'blue'].map((color) => (
                <React.Fragment key={color}>
                    <Divider>
                        <Typography variant="body2">
                            {color.charAt(0).toUpperCase() + color.slice(1)}{' '}
                            Band
                        </Typography>
                    </Divider>
                    <FormControl
                        fullWidth
                        key={color}
                        sx={{ mt: '20px', mb: '10px' }}
                    >
                        <InputLabel id={`${color}-band-label`}>
                            {color.charAt(0).toUpperCase() + color.slice(1)}{' '}
                            Band
                        </InputLabel>
                        <Select
                            required
                            labelId={`${color}-band-label`}
                            value={localVizParams[color] ?? ''}
                            sx={{
                                '& fieldset': {
                                    borderColor: 'primary.main',
                                },
                            }}
                            label={`${
                                color.charAt(0).toUpperCase() + color.slice(1)
                            } Band`}
                            onChange={(event) => {
                                const value = event.target.value
                                updateParam(
                                    color as keyof MultibandRasterVizParams,
                                    value === '' ? undefined : value
                                )
                            }}
                            error={localVizParams[color] === undefined}
                        >
                            {dataset.bands.map((band) => (
                                <MenuItem key={band} value={band}>
                                    {band}
                                </MenuItem>
                            ))}
                        </Select>
                        {localVizParams[color] !== undefined && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    mt: 2,
                                }}
                            >
                                <TextField
                                    label="Min"
                                    type="number"
                                    value={
                                        localVizParams.minMaxesPerBand[
                                            localVizParams[color]
                                        ][0] ?? ''
                                    }
                                    onChange={(e) =>
                                        updateMinOrMax(
                                            localVizParams[color],
                                            'min',
                                            e.target.value === ''
                                                ? null
                                                : Number(e.target.value)
                                        )
                                    }
                                    sx={{
                                        width: '48%',
                                        '& fieldset': {
                                            borderColor: 'primary.main',
                                        },
                                    }}
                                />
                                <TextField
                                    label="Max"
                                    type="number"
                                    value={
                                        localVizParams.minMaxesPerBand[
                                            localVizParams[color]
                                        ][1] ?? ''
                                    }
                                    onChange={(e) =>
                                        updateMinOrMax(
                                            localVizParams[color],
                                            'max',
                                            e.target.value === ''
                                                ? null
                                                : Number(e.target.value)
                                        )
                                    }
                                    sx={{
                                        width: '48%',
                                        '& fieldset': {
                                            borderColor: 'primary.main',
                                        },
                                    }}
                                />
                            </Box>
                        )}
                    </FormControl>
                </React.Fragment>
            ))}
        </Box>
    )
}

function VizParamsEditor({
    dataset,
    flyToDatasetBounds,
}: {
    dataset: Dataset
    flyToDatasetBounds: (dataset: Dataset) => void
}) {
    const { dispatch, updateVizParams } = useMapContext()
    // locally cache the viz params by viz type, so we can switch between viz types without losing the color params
    const [vizParamsForType, setVizParamsForType] = React.useState<{
        [vizType: string]: VizParams
    }>({})
    const [localVizType, setLocalVizType] = useState<VizType>(dataset.vizType)
    const [localVizParams, setLocalVizParams] = useState<VizParams>(
        dataset.vizParams
    )

    // All edits to the viz params are only made to the local viz params, this effect
    // persists the viz params to the global state after a delay, i.e. debouncing
    useEffect(() => {
        const timer = setTimeout(() => {
            updateVizParams(dataset, localVizParams, localVizType)
        }, 500)
        return () => clearTimeout(timer)
    }, [localVizParams])

    const vizParamsCacheKey = `viz_params_${dataset.id}`
    const vizParamsCache = CacheManager.getItem(vizParamsCacheKey)
    const cachedVizParams: {
        [vizType: VizType]: VizParams
    } = vizParamsCache ? JSON.parse(vizParamsCache) : {}

    const updateLocalVizParamsByType = (
        vizType: VizType,
        vizParams: VizParams
    ) => {
        setVizParamsForType({ ...vizParamsForType, [vizType]: vizParams })
        setLocalVizParams(vizParams)
        setLocalVizType(vizType)
        CacheManager.setItem(
            vizParamsCacheKey,
            JSON.stringify(vizParamsForType)
        )
    }

    const handleVizTypeChange = (e) => {
        const newVizType = e.target.value as VizType

        // first cache the current viz params for the current viz type
        updateLocalVizParamsByType(dataset.vizType, dataset.vizParams)

        // see if we have cached viz params for the new viz type
        let newVizParams = cachedVizParams[newVizType]
        if (!newVizParams) {
            newVizParams = getDefaultRasterVizParams(dataset, newVizType)
        }

        // set the viz params for the new viz type
        updateLocalVizParamsByType(newVizType, newVizParams)
        updateVizParams(dataset, newVizParams, newVizType)
    }

    let vizTypeText = ''
    let vizComponent = null
    switch (localVizType) {
        case 'vector':
            vizTypeText = 'Vector'
            vizComponent = VectorVizParamsEditor({ dataset })
            break
        case 'continuous_singleband_raster':
            vizTypeText = 'Raster'
            vizComponent = SingleBandVizParamsEditor({
                dataset,
                localVizParams: localVizParams as PseudocolorRasterVizParams,
                setLocalVizParams,
            })
            break
        case 'continuous_multiband_raster':
            vizTypeText = 'Raster'
            vizComponent = MultibandVizParamsEditor({
                dataset,
                localVizParams: localVizParams as MultibandRasterVizParams,
                setLocalVizParams,
            })
            break
        case 'categorical_raster':
            vizTypeText = 'Raster (categorical)'
            vizComponent = CategoricalVizParamsEditor({ dataset })
            break
    }

    const isContinuous: boolean =
        dataset.vizType === 'continuous_singleband_raster' ||
        dataset.vizType === 'continuous_multiband_raster'

    return (
        <Box
            // Align items horizontally
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
            }}
        >
            {/*  Back arrow */}
            <Box sx={{ alignSelf: 'flex-start' }}>
                <Tooltip title="Go back (Esc)" placement="bottom">
                    <IconButton
                        onClick={() => {
                            dispatch({
                                type: 'TOGGLE_DATASET_EDITING',
                                datasetVersionId: dataset.id,
                            })
                        }}
                        aria-label="back"
                    >
                        <ArrowBack
                            fontSize="inherit"
                            className={VizParamsEditorStyle.icon}
                        />
                    </IconButton>
                </Tooltip>
            </Box>
            {/*  Box for the whole right side */}
            <Box width={'100%'}>
                {/* Header line */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        marginBottom: '10px',
                    }}
                >
                    <Typography
                        variant="h6"
                        textAlign="center"
                        paddingTop={'2px'}
                    >
                        Editing Visualization
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <ZoomButton
                            dataset={dataset}
                            flyToDatasetBounds={flyToDatasetBounds}
                        />
                        <VisibilityButton dataset={dataset} />
                    </Box>
                </Box>
                {/* Dataset description */}
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant="body1">{dataset.name}</Typography>
                    <Typography variant="body2">{vizTypeText}</Typography>
                </Box>
                {/* Actual viz param settings */}
                {isContinuous && (
                    <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                        <InputLabel id="viz-type-label">
                            Visualization Type
                        </InputLabel>
                        <Select
                            labelId="viz-type-label"
                            value={dataset.vizType}
                            label="Visualization Type"
                            sx={{
                                '& fieldset': {
                                    borderColor: 'primary.main',
                                },
                            }}
                            onChange={handleVizTypeChange}
                        >
                            <MenuItem
                                value="continuous_singleband_raster"
                                sx={{ fontSize: '0.85em' }}
                            >
                                Single Band
                            </MenuItem>
                            <MenuItem
                                value="continuous_multiband_raster"
                                sx={{ fontSize: '0.85em' }}
                            >
                                Multiband
                            </MenuItem>
                        </Select>
                    </FormControl>
                )}
                {vizComponent}
            </Box>
        </Box>
    )
}

export default VizParamsEditor
