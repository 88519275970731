import React, { useEffect, useState } from 'react'
import {
    Chip,
    Collapse,
    Divider,
    Icon,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography,
    useTheme,
} from '@mui/material'

import DatasetCatalogStyle from './DatasetCatalog.module.css'
import { Add, ChevronRight, ExpandMore, Remove } from '@mui/icons-material'
import { Dataset } from '../../types/dataset.ts'
import Box from '@mui/material/Box'
import { fetchDatasets } from '../../api/dataset.ts'
import { useSupabaseContext } from '../../context/supabase/supabaseContext.ts'
import DatasetList from '../DatasetList/DatasetList.tsx'
import { useMapContext } from '../../context/map/mapContext.ts'
import SearchBar from '../SearchBar/SearchBar.tsx'
import {
    fetchGoogleDriveFiles,
    fetchGoogleDriveFolders,
    filterFolderByDatasetIds,
} from '../../api/googleDrive.ts'
import { GoogleDriveCatalog } from './GoogleDriveCatalog.tsx'

interface DatasetCatalogProps {
    isOpen: boolean
    setIsOpen: (value: boolean) => void
    maxHeight: string | null
    flyToDatasetBounds: (dataset: Dataset) => void
}

interface InternalCatalogProps {
    datasets: Dataset[]
    flyToDatasetBounds: (dataset: Dataset) => void
}

const InternalCatalog = ({
    datasets,
    flyToDatasetBounds,
}: InternalCatalogProps) => {
    const [open, setOpen] = React.useState(false)
    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <List disablePadding>
            <ListItem
                dense
                disablePadding
                secondaryAction={
                    <Typography variant="body2" color="text.secondary">
                        <Link
                            href="https://earthscale-ai-earthscale-sdk-demo.readthedocs-hosted.com/en/latest/"
                            color="inherit"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            How to add datasets
                        </Link>
                    </Typography>
                }
            >
                <ListItemButton
                    onClick={handleClick}
                    sx={{ borderRadius: '8px', padding: '8px' }}
                >
                    <ListItemIcon
                        style={{
                            minWidth: '0px',
                            paddingRight: '6px',
                            paddingBottom: '4px',
                        }}
                    >
                        {open ? <ExpandMore /> : <ChevronRight />}
                    </ListItemIcon>
                    <ListItemText primary="Catalog" />
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ marginLeft: '10px' }}>
                    <DatasetList
                        datasets={datasets}
                        flyToDatasetBounds={flyToDatasetBounds}
                        showVizSettings={false}
                        showSelector={true}
                        showRemoveFromMap={false}
                        showVisibility={false}
                        disableNonActive={false}
                    />
                </Box>
            </Collapse>
        </List>
    )
}

type ChipFilterProps = {
    filter: string | null
    onFilterSelect: (filter: string) => void
}

const statusToFilterName = {
    ready: 'Ready',
    processing: 'Processing',
    processing_failed: 'Failed',
}

const ChipFilters = ({ filter, onFilterSelect }) => {
    return (
        <Box className={DatasetCatalogStyle.chipBox}>
            {!filter && (
                <>
                    {Object.keys(statusToFilterName).map((status) => (
                        <Chip
                            key={status}
                            label={statusToFilterName[status]}
                            onClick={() => onFilterSelect(status)}
                            className={DatasetCatalogStyle.chip}
                            size="small"
                        />
                    ))}
                </>
            )}
            {filter && (
                <Chip
                    label={statusToFilterName[filter]}
                    onDelete={() => onFilterSelect(null)}
                    className={DatasetCatalogStyle.chip}
                    size="small"
                    color="primary"
                />
            )}
        </Box>
    )
}

const DatasetCatalog = ({
    isOpen,
    setIsOpen,
    maxHeight,
    flyToDatasetBounds,
}: DatasetCatalogProps) => {
    const supabaseContext = useSupabaseContext()
    const [searchTerm, setSearchTerm] = useState('')
    const [statusFilter, setStatusFilter] = useState(null)
    const [googleDriveFolders, setGoogleDriveFolders] = useState<
        GoogleDriveFolder[]
    >([])
    const theme = useTheme()
    const { state, dispatch } = useMapContext()

    const height = isOpen ? maxHeight : null

    const fetchCatalog = async (client) => {
        fetchGoogleDriveFolders(supabaseContext.client).then((folders) => {
            setGoogleDriveFolders(folders)
        })
        fetchDatasets(supabaseContext.client).then((datasets) => {
            dispatch({
                type: 'SET_CATALOG',
                datasets: datasets,
            })
        })
    }

    // Fetch once at the start
    useEffect(() => {
        fetchCatalog()
    }, [])

    // Update catalog every 2 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            if (isOpen) {
                fetchCatalog()
            }
        }, 2000)
        return () => clearInterval(interval)
    }, [isOpen])

    // Sort datasets to first show ones with status "processing_failed", then ones with
    // status "processing" and finally, the rest sorted alphabetically
    const datasetsToShow = [...state.catalog, ...state.current.datasets].sort(
        (a, b) => {
            if (
                a.status === 'processing_failed' &&
                b.status !== 'processing_failed'
            ) {
                return -1
            }
            if (
                a.status !== 'processing_failed' &&
                b.status === 'processing_failed'
            ) {
                return 1
            }
            if (a.status === 'processing' && b.status !== 'processing') {
                return -1
            }
            if (a.status !== 'processing' && b.status === 'processing') {
                return 1
            }
            return a.name.localeCompare(b.name)
        }
    )

    const filteredDatasets = datasetsToShow.filter((dataset) => {
        if (statusFilter && dataset.status !== statusFilter) {
            return false
        }
        return dataset.name.toLowerCase().includes(searchTerm.toLowerCase())
    })

    const internalCatalogDatasets = filteredDatasets.filter((dataset) => {
        return dataset.source === 'internal_catalog'
    })
    const googleDriveDatasets = filteredDatasets.filter((dataset) => {
        return dataset.source === 'google_drive'
    })
    const googleDriveDatasetIds = googleDriveDatasets.map(
        (dataset) => dataset.datasetId
    )
    const filteredGoogleDriveFolders = googleDriveFolders.map((folder) => {
        return filterFolderByDatasetIds(folder, googleDriveDatasetIds)
    })

    const secondaryHeaderText = isOpen ? null : 'Add datasets to your map'
    const datasetListMaxHeight = maxHeight ? `calc(${maxHeight} - 130px)` : null

    const showGoogleDriveCatalog =
        import.meta.env.EARTHSCALE_FEATURE_FLAG_GOOGLE_DRIVE == 'true'

    return (
        <Paper
            className={DatasetCatalogStyle.datasetCatalog}
            sx={{
                height: height,
                maxHeight: maxHeight,
            }}
        >
            <List className={DatasetCatalogStyle.header} dense disablePadding>
                <ListItemButton
                    dense
                    sx={{ borderRadius: '8px', padding: '8px' }}
                    onClick={() => {
                        if (isOpen) {
                            dispatch({ type: 'HIDE_ALL_CATALOG_DATASETS' })
                        }
                        setIsOpen((value) => !value)
                    }}
                >
                    <ListItemText
                        primary="Dataset Browser"
                        secondary={secondaryHeaderText}
                        primaryTypographyProps={{ variant: 'h5' }}
                        secondaryTypographyProps={{ variant: 'body2' }}
                    />
                    <Icon>{isOpen ? <Remove /> : <Add />}</Icon>
                </ListItemButton>
            </List>
            {isOpen && (
                <>
                    <Box sx={{ marginTop: '8px' }} />
                    <SearchBar
                        searchTerm={searchTerm}
                        onSearchChange={setSearchTerm}
                    />
                    <ChipFilters
                        filter={statusFilter}
                        onFilterSelect={setStatusFilter}
                    />
                    <Divider />
                    <Box
                        sx={{
                            maxHeight: datasetListMaxHeight,
                            overflowY: 'auto',
                        }}
                        className={DatasetCatalogStyle.datasetList}
                    >
                        <InternalCatalog
                            datasets={internalCatalogDatasets}
                            flyToDatasetBounds={flyToDatasetBounds}
                        />
                        {showGoogleDriveCatalog && (
                            <GoogleDriveCatalog
                                datasets={googleDriveDatasets}
                                googleDriveFolders={filteredGoogleDriveFolders}
                                flyToDatasetBounds={flyToDatasetBounds}
                            />
                        )}
                    </Box>
                </>
            )}
        </Paper>
    )
}

export default DatasetCatalog
