import { Dataset } from '../types/dataset.ts'
import { PixelInfo } from '../components/PixelInfoView/PixelInfoView'

function formatDate(date: Date): string {
    return date.toISOString().split('T')[0]
}

function createTileServerURL(dataset: Dataset, user?: { api_keys: string }) {
    const selectedDateString =
        dataset.dates && dataset.dates.length > 0
            ? formatDate(dataset.dates[dataset.selectedDateIndex])
            : null

    const dateAddition = selectedDateString ? '&date=' + selectedDateString : ''

    const vizParamsAddition = dataset.vizId ? '&viz_id=' + dataset.vizId : ''

    const keyAddition = user ? '&key=' + user.api_keys[0] : ''

    const extension = dataset.type == 'raster' ? 'png' : 'mvt'
    const url =
        import.meta.env.EARTHSCALE_TILER_URL +
        '/tiles/{z}/{x}/{y}.' +
        extension +
        '?dataset=' +
        dataset.id +
        dateAddition +
        vizParamsAddition +
        keyAddition
    return url
}

async function getPixelInfo(
    dataset: Dataset,
    lat: number,
    lon: number,
    accessToken: string
): Promise<PixelInfo> {
    const selectedDateString =
        dataset.dates && dataset.dates.length > 0
            ? formatDate(dataset.dates[dataset.selectedDateIndex])
            : null

    const dateAddition = selectedDateString ? '&date=' + selectedDateString : ''

    const url =
        import.meta.env.EARTHSCALE_TILER_URL +
        '/tiles/pixel-info?dataset=' +
        dataset.id +
        '&lat=' +
        lat +
        '&lon=' +
        lon +
        dateAddition

    const response = await fetch(url, {
        headers: {
            Authorization: accessToken,
        },
    })
    const pixelInfoJSON = await response.json()
    const pixelInfo: PixelInfo = {
        datasetName: dataset.name,
        datasetType: dataset.type,
        json: pixelInfoJSON,
    }
    return pixelInfo
}

export { createTileServerURL, getPixelInfo }
