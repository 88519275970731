export function boundsStringToArray(
    boundsString: string
): [number, number, number, number] {
    // Bounds is a postgres string in the form of
    // 'BOX(xmin ymin, xmax ymax)'
    const parsedBounds = boundsString
        .replace('BOX(', '')
        .replace(')', '')
        .replace(',', ' ')
        .split(' ')
        .map(parseFloat)
    if (parsedBounds.length !== 4) {
        throw new Error('Invalid bounds string: ' + boundsString)
    }
    return parsedBounds as [number, number, number, number]
}

export function formatDate(date: Date): string {
    return date.toISOString().split('T')[0]
}

export function formatDateTimeForDisplay(date: Date) {
    let locale = 'en-US'
    if (typeof navigator !== 'undefined' && navigator.language) {
        locale = navigator.language
    }

    const formatter = new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
    })

    return formatter.format(date)
}
