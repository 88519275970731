import * as React from 'react'
import { Dataset } from '../../types/dataset.ts'
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    ListItemText,
    Stack,
    Tooltip,
} from '@mui/material'
import DatasetCardStyle from './DatasetCard.module.css'
import VisibilityButton from './VisibilityButton'
import ZoomButton from './ZoomButton'
import { Delete, Warning } from '@mui/icons-material'
import { useState } from 'react'
import DateSlider from './DateSlider'
import { formatDate } from '../../utils'
import { useMapContext } from '../../context/map/mapContext.ts'
import raster from '../../assets/icons/raster.svg'
import EditVizButton from './EditVizButton.tsx'
import { PROCESSING_STATES } from '../../api/dataset.ts'

type DatasetCardProps = {
    dataset: Dataset
    flyToDatasetBounds: (dataset: Dataset) => void
    showVizSettings: boolean
    showRemoveFromMap: boolean
    showVisibility: boolean
}

type DatasetDeleteButtonProps = {
    onClick: () => void
}

function DatasetDeleteButton({ onClick }: DatasetDeleteButtonProps) {
    return (
        <Tooltip title={'Delete Dataset from Catalog'}>
            <IconButton onClick={onClick}>
                <Delete />
            </IconButton>
        </Tooltip>
    )
}

function DatasetCard({
    dataset,
    flyToDatasetBounds,
    showVizSettings,
    showRemoveFromMap,
    showVisibility,
}: DatasetCardProps) {
    const { dispatch, deleteDatasetFromCatalog, removeDatasetFromMap } =
        useMapContext()
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [removeFromMapDialogOpen, setRemoveFromMapDialogOpen] =
        React.useState(false)
    const [datasetToDelete, setDatasetToDelete] = useState<Dataset | null>(null)

    const visibilityButton = VisibilityButton({
        dataset,
    })
    const zoomButton = ZoomButton({
        dataset,
        flyToDatasetBounds,
        className: DatasetCardStyle.hoverIcon,
    })

    let dateString = null
    if (dataset.dates && dataset.dates.length > 1) {
        dateString = ' ' + formatDate(dataset.dates[dataset.selectedDateIndex])
    }

    const backgroundColor = dataset.vizParams?.color || 'white'

    let secondaryText =
        dataset.type == 'raster' ? 'Raster Dataset' : 'Vector Dataset'
    if (dataset.source == 'local') {
        secondaryText =
            'Local ' +
            secondaryText +
            ' - Only visible to you and not persisted'
    }

    const handleDeleteDatasetClose = () => {
        setDeleteDialogOpen(false)
        setDatasetToDelete(null)
    }

    const handleRemoveFromMapDialogClose = () => {
        setRemoveFromMapDialogOpen(false)
    }

    const handleRemoveFromMap = () => {
        removeDatasetFromMap(dataset.id)
        setRemoveFromMapDialogOpen(false)
    }

    return (
        <>
            {/*Remove dataset from map dialog*/}
            <Dialog
                open={removeFromMapDialogOpen}
                onClose={handleRemoveFromMapDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    "{dataset.name}" will be deleted from this map
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleRemoveFromMapDialogClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleRemoveFromMap} autoFocus>
                        Remove from this map
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Delete dataset dialog*/}
            {datasetToDelete && (
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleDeleteDatasetClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Delete Dataset "{datasetToDelete.name}" from Catalog?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete the dataset "
                            {datasetToDelete.name}" from the catalog? This
                            action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteDatasetClose}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                deleteDatasetFromCatalog(datasetToDelete.id)
                                handleDeleteDatasetClose()
                            }}
                            autoFocus
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Box className={DatasetCardStyle.baseCard}>
                {dataset.type == 'raster' && (
                    <Box className={DatasetCardStyle.typeIcon}>
                        <img
                            src={raster as string}
                            style={{ width: '16px', height: '16px' }}
                        />
                    </Box>
                )}
                {dataset.type == 'vector' && (
                    <Box
                        className={DatasetCardStyle.typeIcon}
                        backgroundColor={backgroundColor}
                    />
                )}
                <Stack width={'100%'}>
                    <Box className={DatasetCardStyle.cardLine}>
                        <ListItemText
                            primary={dataset.name}
                            secondary={secondaryText}
                            primaryTypographyProps={{ variant: 'h6' }}
                            secondaryTypographyProps={{ variant: 'body2' }}
                            className={DatasetCardStyle.cardText}
                            style={{ wordBreak: 'break-all' }}
                        />{' '}
                        <Box className={DatasetCardStyle.buttonBox}>
                            {PROCESSING_STATES.includes(dataset.status) && (
                                <Stack direction="row">
                                    <DatasetDeleteButton
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation()
                                            setDeleteDialogOpen(true)
                                            setDatasetToDelete(dataset)
                                        }}
                                    />
                                    <Tooltip title={'Processing'}>
                                        <CircularProgress
                                            size={'20px'}
                                            sx={{
                                                mt: '9px',
                                                mr: '17px',
                                                ml: '4px',
                                            }}
                                            color="primary"
                                        />
                                    </Tooltip>
                                </Stack>
                            )}
                            {showRemoveFromMap && (
                                <Tooltip title={'Remove from this map'}>
                                    <IconButton
                                        onClick={() =>
                                            setRemoveFromMapDialogOpen(true)
                                        }
                                        className={DatasetCardStyle.hoverIcon}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {dataset.status === 'processing_failed' && (
                                <Stack direction="row">
                                    <DatasetDeleteButton
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation()
                                            setDeleteDialogOpen(true)
                                            setDatasetToDelete(dataset)
                                        }}
                                    />
                                    <Tooltip
                                        title={'Click to see error details'}
                                    >
                                        <Warning
                                            sx={{
                                                mt: '9px',
                                                mr: '17px',
                                                ml: '4px',
                                            }}
                                            color="error"
                                        />
                                    </Tooltip>
                                </Stack>
                            )}
                            {dataset.status === 'ready' && (
                                <>
                                    {dataset.className !=
                                        'EarthEngineDataset' &&
                                        showVizSettings && (
                                            <EditVizButton
                                                datasetVersionId={dataset.id}
                                            />
                                        )}
                                    {zoomButton}
                                    {showVisibility && visibilityButton}
                                </>
                            )}
                        </Box>
                    </Box>
                    {dataset.dates && dataset.dates.length > 1 && (
                        <DateSlider dataset={dataset} />
                    )}
                </Stack>
            </Box>
        </>
    )
}

export default DatasetCard
