import React, { useEffect, useState } from 'react'
import { Button, Tooltip } from '@mui/material'
import { useCommentContext } from '../../context/comment/commentContext'

import ChatIcon from '@mui/icons-material/Chat'

const CommentModeBox: React.FC = () => {
    const { state, toggleCommentMode } = useCommentContext()
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 })

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            if (state.isCommentMode) {
                setCursorPosition({ x: e.clientX, y: e.clientY })
            }
        }

        if (state.isCommentMode) {
            window.addEventListener('mousemove', handleMouseMove)
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
        }
    }, [state.isCommentMode])

    const FloatingCommentIcon = () => {
        if (!state.isCommentMode) return null

        return (
            <ChatIcon
                sx={{
                    position: 'fixed',
                    left: cursorPosition.x + 3,
                    top: cursorPosition.y + 12,
                    color: 'white',
                    pointerEvents: 'none',
                    zIndex: 9999,
                    opacity: 0.8,
                    fontSize: '0.8em',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    borderRadius: '50%',
                    padding: '4px',
                }}
            />
        )
    }

    return (
        <>
            <FloatingCommentIcon />
            <Tooltip
                title={
                    state.isCommentMode
                        ? 'Disable comment mode'
                        : 'Enable comment mode'
                }
            >
                <Button
                    variant="outlined"
                    size="small"
                    onClick={toggleCommentMode}
                    sx={{
                        zIndex: 1000,
                        minWidth: '48px',
                        marginRight: '12px',
                        borderRadius: '20px',
                        backgroundColor: state.isCommentMode
                            ? 'rgba(39, 46, 45, 0.9)'
                            : 'var(--panel-background)',
                        color: 'white',
                        borderColor: state.isCommentMode
                            ? 'rgba(144, 202, 249, 0.5)'
                            : 'rgba(230, 246, 231, 0.5)',
                        padding: '8px',
                        '&:hover': {
                            borderRadius: 'var(--borderRadius, 20px)',
                            border: state.isCommentMode
                                ? '1px solid rgba(144, 202, 249, 0.65)'
                                : '1px solid rgba(230, 246, 231, 0.65)',
                            background: 'rgba(39, 46, 45, 0.7)',
                            backdropFilter: 'blur(8px)',
                        },
                        transition: 'all 0.3s ease',
                    }}
                >
                    <ChatIcon
                        sx={{
                            color: state.isCommentMode ? '#90CAF9' : 'white',
                            transition: 'color 0.3s ease',
                        }}
                    />
                </Button>
            </Tooltip>
        </>
    )
}

export default CommentModeBox
