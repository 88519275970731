import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link as LinkIcon, CheckCircle } from '@mui/icons-material'
import { IconButton, Tooltip, Box, Typography, Button } from '@mui/material'
import { useMapContext } from '../../context/map/mapContext'
import ShareBoxStyles from './ShareBox.module.css'
const createMapLink = (mapId: string): string => {
    const rootUrl = window.location.origin
    return `${rootUrl}/map/${mapId}`
}

const ShareBox: React.FC = () => {
    const [copied, setCopied] = useState(false)
    const { state } = useMapContext()
    const currentMap = state.current.metadata

    const handleCopy = () => {
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    return (
        <CopyToClipboard
            text={createMapLink(currentMap.id)}
            onCopy={handleCopy}
        >
            <Button
                variant="outlined"
                size="small"
                startIcon={
                    copied ? (
                        <CheckCircle color="success" />
                    ) : (
                        <LinkIcon color="primary" />
                    )
                }
                sx={{
                    borderRadius: '20px',
                    backgroundColor: 'var(--panel-background)',
                    color: 'white',
                    borderColor: 'rgba(230, 246, 231, 0.5)',
                    '&:hover': {
                        borderRadius: 'var(--borderRadius, 20px)',
                        border: '1px solid rgba(230, 246, 231, 0.65)',
                        background: 'rgba(39, 46, 45, 0.7)',
                        backdropFilter: 'blur(8px)',
                    },
                    padding: '8px 16px',
                    transition: 'all 0.3s ease',
                }}
            >
                {copied ? 'LINK COPIED' : 'SHARE MAP'}
            </Button>
        </CopyToClipboard>
    )
}

export default ShareBox
