import { CenterFocusWeak } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import Box from '@mui/material/Box'

interface ZoomButtonProps {
    dataset: any
    flyToDatasetBounds: (dataset: any) => void
    className?: string | null
}

function ZoomButton({
    dataset,
    flyToDatasetBounds,
    className,
}: ZoomButtonProps) {
    const zoomToDataset = (e: React.MouseEvent) => {
        e.stopPropagation()
        flyToDatasetBounds(dataset)
    }

    return (
        <Box>
            <Tooltip title="Zoom to dataset" arrow>
                <IconButton onClick={zoomToDataset}>
                    <CenterFocusWeak className={className} />
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export default ZoomButton
