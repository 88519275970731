import { SupabaseClient } from '@supabase/supabase-js'
import {
    Dataset,
    DatasetDomain,
    DatasetProcessingStatus,
    DatasetType,
    VizType,
} from '../types/dataset'
import { v4 as uuid4 } from 'uuid'
import {
    MultibandRasterVizParams,
    PseudocolorRasterVizParams,
    Viz,
    VizParams,
} from '../types/viz'

type _DatasetMetadata = {
    value_map?: { [key: string]: number | number[] }
    bands?: string[]
    min_zoom: number
    min_maxes_per_band?: {
        [key: string]: [number | undefined, number | undefined]
    }
}

type DBDataset = {
    id: string
    dataset_id: string
    name: string
    updated_at: string
    type: DatasetType
    is_deleted: boolean
    extent: string
    dates: Date[]
    class_name: string
    metadata: _DatasetMetadata
    status: DatasetProcessingStatus
    domain: DatasetDomain
    org_id: string
    user_id: string
    viz_params?: DBVizParams[]
    thumbnail_url?: string | null
}

type DBVizParams = {
    id: string
    type: VizType
    params: VizParams
    dataset_version_id: string
    created_at: string
    dataset_name: string
}

const PROCESSING_STATES = ['processing', 'not_started']

function ensureVizParamsAreValid(dataset: Dataset): Dataset {
    const paramTypesToCheck = [
        'continuous_singleband_raster',
        'continuous_multiband_raster',
    ]
    if (!paramTypesToCheck.includes(dataset.vizType)) {
        return dataset
    }

    let params: PseudocolorRasterVizParams | MultibandRasterVizParams
    if (dataset.vizType === 'continuous_singleband_raster') {
        params = dataset.vizParams as PseudocolorRasterVizParams
    } else if (dataset.vizType === 'continuous_multiband_raster') {
        params = dataset.vizParams as MultibandRasterVizParams
    }

    // TODO: this is a temporary hack to ensure that the min/max values are always present in the viz params.
    // we've since updated the backend to always return min/max values, but this is a stopgap measure to ensure
    // that old viz params are still compatible with the new backend without a migration
    // https://github.com/earthscale/earthscale/pull/239
    const currentMinMaxesPerBand = {
        ...Object.fromEntries(
            Object.entries(dataset.minMaxesPerBand).map(
                ([band, [min, max]]) => [band, [min ?? 0, max ?? 1]]
            )
        ),
        ...Object.fromEntries(
            Object.entries(params.minMaxesPerBand).map(([band, [min, max]]) => [
                band,
                [min ?? 0, max ?? 1],
            ])
        ),
    }
    dataset.vizParams.minMaxesPerBand = currentMinMaxesPerBand

    return dataset
}

async function insertVizParams(
    supabaseClient: SupabaseClient,
    datasetVersionId: string,
    datasetName: string,
    vizId: string,
    vizType: VizType,
    vizParams: VizParams
): Promise<void> {
    const row = {
        id: vizId,
        type: vizType,
        params: vizParams,
        dataset_version_id: datasetVersionId,
        dataset_name: datasetName,
        created_at: new Date().toISOString(),
    }

    const { data, error } = await supabaseClient
        .from('viz_params')
        .insert([row])

    if (error) {
        console.error(error)
    }
}

function datasetCopyHydratedProperties(from: Dataset, to: Dataset): void {
    to.isVisible = from.isVisible
    to.selectedDateIndex = from.selectedDateIndex
    to.isBeingEdited = from.isBeingEdited
    to.cardIsHovered = from.cardIsHovered
    to.isNewVersionAvailable = from.isNewVersionAvailable
}

function datasetInitHydratedProperties(dataset: Dataset): void {
    dataset.isVisible = false
    dataset.selectedDateIndex = 0
    dataset.isBeingEdited = false
    dataset.cardIsHovered = false
    dataset.isNewVersionAvailable = false
}

/*
 Map to local state prop naming conventions
 */
const PROP_MAP = {
    dataset_id: 'datasetId',
    created_at: 'createdAt',
    updated_at: 'updatedAt',
    is_deleted: 'isDeleted',
    class_name: 'className',
    is_public: 'isPublic',
}

async function convertDBDatasetToDataset(
    dbDataset: DBDataset,
    viz: Viz | null,
    supabaseClient: SupabaseClient
): Promise<Dataset> {
    const databaseObject = Object.fromEntries(
        Object.entries(dbDataset).map(([key, value]) => [
            PROP_MAP[key] || key,
            value,
        ])
    )
    delete databaseObject.metadata
    const dataset = databaseObject as Dataset

    dataset.thumbnailUrl = dbDataset.metadata?.thumbnail_url || null

    // Set the values we hydrate in addition to the database model
    datasetInitHydratedProperties(dataset)

    if (dbDataset.status == 'ready') {
        // Convert YYYY-MM-DD to Date
        if (dbDataset.dates) {
            dataset.dates = dbDataset.dates.map((date) => new Date(date))
        }

        // Metadata contains min_zoom, bands, viz params, and min-maxes
        dataset.bands = dbDataset.metadata.bands
        dataset.minZoom = dbDataset.metadata.min_zoom || 0

        if (dbDataset.metadata.min_maxes_per_band) {
            dataset.minMaxesPerBand = dbDataset.metadata.min_maxes_per_band
        } else {
            // Set all min/max values to [undefined, undefined] if nothing is given in the metadata
            dataset.minMaxesPerBand = {}
            dataset.bands.forEach((band) => {
                dataset.minMaxesPerBand[band] = [undefined, undefined]
            })
        }

        // Visualizations may not exist if it comes from a tileserver.
        if (viz) {
            dataset.vizType = viz.vizType
            dataset.vizParams = viz.vizParams
            dataset.vizId = viz.id
        }
    }

    const validatedDataset = ensureVizParamsAreValid(dataset)
    return validatedDataset
}

async function fetchDatasets(
    supabaseClient: SupabaseClient
): Promise<Dataset[]> {
    const latestDatasetsQuery = supabaseClient
        .from('datasets_latest')
        .select('*, viz_params(*)')
        .order('updated_at', { ascending: false })
    const { data, error } = await latestDatasetsQuery
    if (error) {
        console.error(error)
        return
    }

    // Convert DBDataset to Dataset
    const datasets = data.map(async (dataset: DBDataset) => {
        const latestDBVizParams = dataset.viz_params.sort((a, b) => {
            return (
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )
        })

        // Visualizations may not exist if it comes from a tileserver.
        let latestViz: Viz | null = null
        if (latestDBVizParams.length > 0) {
            latestViz = {
                id: latestDBVizParams[0].id,
                vizType: latestDBVizParams[0].type,
                vizParams: latestDBVizParams[0].params,
            }
        }

        return convertDBDatasetToDataset(dataset, latestViz, supabaseClient)
    })

    return Promise.all(datasets).then((results) =>
        results.filter((dataset) => dataset !== undefined)
    )
}

async function deleteDataset(
    supabaseClient: SupabaseClient,
    datasetVersionId: string
) {
    // Deletion works by creating a copy of the dataset with a new ID and setting
    // is_deleted to true
    const newDatasetVersionId = uuid4()
    // First, fetching the dataset
    const { data, error } = await supabaseClient
        .from('dataset_versions')
        .select('*')
        .eq('id', datasetVersionId)
    if (error) {
        console.error(error)
        return
    }
    const currentVersion = data[0]
    // Then, inserting the new dataset, make sure that updated_at is set to the current
    // time
    const { data: insertData, insertError } = await supabaseClient
        .from('dataset_versions')
        .insert({
            ...currentVersion,
            id: newDatasetVersionId,
            is_deleted: true,
            updated_at: new Date().toISOString(),
        })
    if (insertError) {
        console.error(insertError)
        return
    }
}

export type { DBDataset, DBVizParams }
export {
    convertDBDatasetToDataset,
    datasetCopyHydratedProperties,
    fetchDatasets,
    insertVizParams,
    deleteDataset,
    PROCESSING_STATES,
}
