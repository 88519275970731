import { Dataset } from '../../types/dataset.ts'
import React, { useState } from 'react'
import {
    Collapse,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import { ChevronRight, ErrorOutline, ExpandMore } from '@mui/icons-material'
import DatasetList from '../DatasetList/DatasetList.tsx'

interface FolderProps {
    folder: GoogleDriveFolder
    driveDatasets: Dataset[]
    isToplevel: boolean
}

const getFilesFromFolder = (
    folder: GoogleDriveFolder,
    files: GoogleDriveFile[]
) => {
    files.push(...folder.files)
    folder.subfolders.forEach((subfolder) => {
        getFilesFromFolder(subfolder, files)
    })
    return files
}

type FolderComponentProps = {
    folder: GoogleDriveFolder
    driveDatasets: Dataset[]
    isToplevel: boolean
    flyToDatasetBounds: (dataset: Dataset) => void
}

const FolderComponent: React.FC<FolderProps> = ({
    folder,
    driveDatasets,
    isToplevel,
    flyToDatasetBounds,
}: FolderComponentProps) => {
    const [open, setOpen] = useState(false)

    const handleToggle = () => {
        setOpen(!open)
    }

    const datasetIdsToShow = folder.files.map((file) => file.datasetId)
    const datasetsToShow = driveDatasets.filter((dataset) =>
        datasetIdsToShow.includes(dataset.datasetId)
    )

    let progress = null
    let buffer = null
    let failedDatasetsCount = null
    if (isToplevel) {
        const allFilesForFolder = getFilesFromFolder(folder, [])
        const allDatasetIdsForFolder = allFilesForFolder.map(
            (file) => file.datasetId
        )
        const allDatasetsForFolder = driveDatasets.filter((dataset) =>
            allDatasetIdsForFolder.includes(dataset.datasetId)
        )
        const processingDatasetsCount = allDatasetsForFolder.filter(
            (dataset) => dataset.status === 'processing'
        ).length
        const readyDatasetsCount = allDatasetsForFolder.filter(
            (dataset) => dataset.status === 'ready'
        ).length
        failedDatasetsCount = allDatasetsForFolder.filter(
            (dataset) => dataset.status === 'processing_failed'
        ).length
        progress =
            ((readyDatasetsCount + failedDatasetsCount) /
                allDatasetsForFolder.length) *
            100
        buffer = (processingDatasetsCount / allDatasetsForFolder.length) * 100
    }

    const sortedSubfolders = [...folder.subfolders]
    sortedSubfolders.sort((a, b) => a.name.localeCompare(b.name))

    return (
        <Box>
            <ListItem dense disablePadding key={folder.id}>
                <ListItemButton
                    onClick={handleToggle}
                    sx={{ borderRadius: '8px', padding: '8px' }}
                >
                    <ListItemIcon
                        style={{
                            minWidth: '0px',
                            paddingRight: '6px',
                            paddingBottom: '4px',
                        }}
                    >
                        {open ? <ExpandMore /> : <ChevronRight />}
                    </ListItemIcon>
                    <ListItemText primary={folder.name} />
                </ListItemButton>
            </ListItem>
            {isToplevel &&
                progress != null &&
                !isNaN(progress) &&
                progress != 100 && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '90%',
                            ml: 'auto',
                            mb: '5px',
                        }}
                    >
                        <Typography
                            variant={'body2'}
                            sx={{ color: 'text.secondary' }}
                        >
                            Processing
                        </Typography>
                        <Box sx={{ width: '100%', ml: '10px', mr: '10px' }}>
                            <LinearProgress
                                variant="buffer"
                                value={progress}
                                valueBuffer={buffer}
                            />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                            <Typography
                                variant="body2"
                                sx={{ color: 'text.secondary' }}
                            >{`${Math.round(progress)}%`}</Typography>
                        </Box>
                        {failedDatasetsCount > 0 && (
                            <Tooltip
                                title={
                                    failedDatasetsCount +
                                    ' dataset(s) failed to process'
                                }
                            >
                                <ErrorOutline sx={{ mr: '10px' }} />
                            </Tooltip>
                        )}
                    </Box>
                )}
            <Box sx={{ ml: '10px' }}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List disablePadding={true} dense>
                        <DatasetList
                            datasets={datasetsToShow}
                            disableNonActive={false}
                            showVizSettings={false}
                            flyToDatasetBounds={flyToDatasetBounds}
                            showSelector={true}
                            showVisibility={false}
                            showRemoveFromMap={false}
                        />
                        {sortedSubfolders.map((subfolder) => (
                            <FolderComponent
                                folder={subfolder}
                                driveDatasets={driveDatasets}
                                key={subfolder.id}
                                isToplevel={false}
                                flyToDatasetBounds={flyToDatasetBounds}
                            />
                        ))}
                    </List>
                </Collapse>
            </Box>
        </Box>
    )
}

interface FolderTreeProps {
    folders: GoogleDriveFolder[]
    driveDatasets: Dataset[]
    flyToDatasetBounds: (dataset: Dataset) => void
}

export const FolderTree: React.FC<FolderTreeProps> = ({
    folders,
    driveDatasets,
    flyToDatasetBounds,
}) => {
    return (
        <List disablePadding dense>
            {folders.map((folder) => (
                <FolderComponent
                    folder={folder}
                    driveDatasets={driveDatasets}
                    key={folder.id}
                    isToplevel={true}
                    flyToDatasetBounds={flyToDatasetBounds}
                />
            ))}
        </List>
    )
}
