import * as React from 'react'
import { useState } from 'react'
import Stack from '@mui/material/Stack'
import SidebarStyle from './Sidebar.module.css'
import {
    Alert,
    Box,
    Divider,
    Paper,
    Snackbar,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import { Dataset } from '../../types/dataset.js'
import VizParamsEditor from '../VizParamsEditor/VizParamsEditor'
import MapBox from './MapBox.js'
import { useMapContext } from '../../context/map/mapContext.js'
import Header from './Header.js'
import DatasetList from '../DatasetList/DatasetList.js'
import SearchBar from '../SearchBar/SearchBar.js'
import { Add } from '@mui/icons-material'
import {
    addLocalDataset,
    convertLocalDatasetToDataset,
} from '../../api/localDataset.js'

type SidebarProps = {
    flyToDatasetBounds: (dataset: Dataset) => void
    maxHeight: string | null
}

function Sidebar({ flyToDatasetBounds, maxHeight }: SidebarProps) {
    const {
        state,
        dispatch,
        removeDatasetFromMap,
        addDatasetToMap,
        lastDatasetRef,
        changeMap,
    } = useMapContext()
    const [searchTerm, setSearchTerm] = useState('')
    const theme = useTheme()
    const [isDragOver, setIsDragOver] = useState(false)
    const [error, setError] = useState<string>(null)

    let datasets = state.current.datasets
    const searchShouldShow = datasets.length > 8
    if (searchShouldShow && searchTerm != '') {
        datasets = datasets.filter((dataset) =>
            dataset.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
    }

    const handleAddDataset = (dataset: Dataset) => {
        addDatasetToMap(dataset)
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        const file = event.dataTransfer.files[0]
        if (
            file &&
            (file.type === 'application/json' ||
                file.type === 'application/geo+json')
        ) {
            const reader = new FileReader()
            reader.onload = (e) => {
                try {
                    if (file.size > 10 * 1024 * 1024) {
                        const fileSizeInMb = file.size / (1024 * 1024)
                        // Throw error
                        throw new Error(
                            `File ${file.name} too large, the maximum file size we're supporting is 10mb. The file has ${fileSizeInMb.toFixed(2)}mb.`
                        )
                    }

                    const filename = file.name
                    const content = JSON.parse(e.target.result as string)
                    const mapId = state.current.metadata.id
                    const localDataset = {
                        mapId: mapId,
                        filename: filename,
                        createdAt: new Date(file.lastModified).toISOString(),
                        geometry: content,
                    }
                    try {
                        // Make sure we can convert the local dataset to a dataset
                        // This will raise an error if there is a conversion.
                        convertLocalDatasetToDataset(localDataset)
                    } catch (e) {
                        throw new Error(
                            `The file might not be a correct geojson.`
                        )
                    }
                    addLocalDataset(localDataset)
                    // Calling change map here causes a re-load from the local storage
                    // immediately
                    changeMap(mapId)
                    setIsDragOver(false)
                } catch (error) {
                    const errorMessage = `Failed to add file ${file.name}, error: ${error.message}`
                    setError(errorMessage)
                }
            }
            reader.readAsText(file)
        }
    }

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        setIsDragOver(true)
    }

    const handleDragLeave = () => {
        setIsDragOver(false)
    }

    const datasetBeingEdited = datasets.find((dataset) => dataset.isBeingEdited)

    React.useEffect(() => {
        const datasetBeingEdited = datasets.find((d) => d.isBeingEdited)
        if (datasetBeingEdited) {
            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === 'Escape' && datasetBeingEdited) {
                    dispatch({
                        type: 'TOGGLE_DATASET_EDITING',
                        datasetVersionId: datasetBeingEdited.id,
                    })
                }
            }
            window.addEventListener('keydown', handleKeyDown)
            return () => window.removeEventListener('keydown', handleKeyDown)
        }
    }, [datasets])

    // Values empirically determined
    const datasetListMaxHeight = maxHeight
        ? `calc(${maxHeight} - 265px)`
        : `calc(100vh - 390px)`

    return (
        <Paper
            className={SidebarStyle.sidebar}
            sx={{
                maxHeight: maxHeight,
            }}
        >
            <Stack direction="column" spacing={1}>
                <Header />
                <Divider />
                <MapBox />
                {searchShouldShow && (
                    <Box sx={{ padding: '0px 8px' }}>
                        <SearchBar
                            searchTerm={searchTerm}
                            onSearchChange={setSearchTerm}
                        />
                    </Box>
                )}
                <Box
                    sx={{
                        maxHeight: datasetListMaxHeight,
                        overflowY: 'auto',
                    }}
                >
                    {datasetBeingEdited && (
                        <VizParamsEditor
                            dataset={datasetBeingEdited}
                            flyToDatasetBounds={flyToDatasetBounds}
                        />
                    )}
                    {!datasetBeingEdited &&
                        state.current.datasets.length > 0 && (
                            <>
                                <DatasetList
                                    datasets={datasets}
                                    flyToDatasetBounds={flyToDatasetBounds}
                                    showVizSettings={true}
                                    showSelector={false}
                                    showRemoveFromMap={true}
                                    showVisibility={true}
                                    disableNonActive={true}
                                />
                                <div ref={lastDatasetRef} />
                            </>
                        )}
                </Box>
                <Box
                    className={SidebarStyle.dragAndDropArea}
                    sx={{
                        border: '1px dashed ' + theme.typography.body2.color,
                        backgroundColor: isDragOver
                            ? theme.palette.action.hover
                            : 'transparent',
                    }}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                >
                    <Tooltip
                        title={
                            'Files added by drag & drop will be stored locally in ' +
                            'your browser and only be visible to you. The files ' +
                            'never leave your computer.'
                        }
                    >
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Add sx={{ color: theme.typography.body2.color }} />
                            <Typography
                                variant="body2"
                                sx={{ paddingTop: '2px' }}
                            >
                                Drop GeoJSON files
                            </Typography>
                        </Stack>
                    </Tooltip>
                </Box>
            </Stack>
            <Snackbar
                open={error !== null}
                autoHideDuration={5000}
                onClose={() => setError(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                sx={{ width: '400px' }}
            >
                <Alert
                    onClose={() => setError(null)}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                    key={error}
                >
                    {error}
                </Alert>
            </Snackbar>
        </Paper>
    )
}

export default Sidebar
