import React, { Context, createContext, useContext } from 'react'
import { MapAction, MapState } from './mapReducer.ts'
import { Dataset, VizType } from '../../types/dataset.ts'
import { VizParams } from '../../types/viz.ts'

type MapContextType = {
    state: MapState
    dispatch: React.Dispatch<MapAction>
    createNewMap: () => void
    changeMap: (mapId: string) => void
    updateMapMetadata: (name: string, description: string) => void
    addDatasetToMap: (dataset: Dataset) => void
    removeDatasetFromMap: (datasetId: string) => void
    updateVizParams: (
        dataset: Dataset,
        vizParams: VizParams,
        vizType: VizType
    ) => void
    lastDatasetRef: React.MutableRefObject<HTMLDivElement> | null
    deleteDatasetFromCatalog: (datasetId: string) => void
}

export const MapContext = createContext<MapContextType>(null)

export const useMapContext = () => useContext(MapContext)
